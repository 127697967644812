import { GetCookie, SetCookie } from "./cookies";
import {
  AFFID,
  BAIDU,
  BING,
  CXD,
  DUCKDUCKGO,
  ESCOSIA,
  EXDAYS,
  GOOGLE,
  PCODE,
  QWANT,
  YAHOO,
  YANDEX,
} from "./types";
import {
  IsInvalid,
  IsSameDomain,
  WriteInitRef,
  WriteLastRef,
} from "./referrer-handler";
import { GetUrlParams } from "./get-url-params";

// main
const AffiliateTracker = (domains, targetElement) => {
  var currentReferer = document.referrer;

  if (IsSameDomain(currentReferer)) {
    if (IsInvalid(currentReferer)) {
      currentReferer = "direct";
    }
  }

  if (currentReferer === "direct") {
    WriteInitRef();
    WriteLastRef();
  } else if (GOOGLE === true) {
    SetCookie("utm_medium", "organic", EXDAYS);
    SetCookie("utm_source", "google", EXDAYS);
  } else if (BING === true) {
    SetCookie("utm_medium", "organic", EXDAYS);
    SetCookie("utm_source", "bing", EXDAYS);
  } else if (BAIDU === true) {
    SetCookie("utm_medium", "organic", EXDAYS);
    SetCookie("utm_source", "baidu", EXDAYS);
  } else if (DUCKDUCKGO === true) {
    SetCookie("utm_medium", "organic", EXDAYS);
    SetCookie("utm_source", "duckduckgo", EXDAYS);
  } else if (YAHOO === true) {
    SetCookie("utm_medium", "organic", EXDAYS);
    SetCookie("utm_source", "yahoo", EXDAYS);
  } else if (ESCOSIA === true) {
    SetCookie("utm_medium", "organic", EXDAYS);
    SetCookie("utm_source", "ecosia", EXDAYS);
  } else if (YANDEX === true) {
    SetCookie("utm_medium", "organic", EXDAYS);
    SetCookie("utm_source", "yandex", EXDAYS);
  } else if (QWANT === true) {
    SetCookie("utm_medium", "organic", EXDAYS);
    SetCookie("utm_source", "qwant", EXDAYS);
  } else {
    var existingUtmSource = GetCookie("utm_source");
    if (!existingUtmSource) {
      SetCookie("utm_medium", "referral", EXDAYS);
      SetCookie("utm_source", currentReferer, EXDAYS);
      SetCookie("utm_campaign", "", EXDAYS);
    }
  }

  if (GetUrlParams("utm_medium")) {
    var utm_medium = GetUrlParams("utm_medium");
  } else {
    utm_medium = GetCookie("utm_medium");
  }

  if (GetUrlParams("utm_source")) {
    var utm_source = GetUrlParams("utm_source");
  } else {
    utm_source = GetCookie("utm_source");
  }

  if (GetUrlParams("utm_campaign")) {
    var utm_campaign = GetUrlParams("utm_campaign");
  } else {
    utm_campaign = GetCookie("utm_campaign");
  }

  if (
    (utm_medium !== "" && utm_medium !== undefined) ||
    (utm_source !== "" && utm_source !== undefined)
  ) {
    SetCookie("utm_medium", utm_medium, EXDAYS);
    SetCookie("utm_source", utm_source, EXDAYS);
    SetCookie("utm_campaign", utm_campaign, EXDAYS);
  }

  var Pcode = GetUrlParams(PCODE);
  var cxd = GetUrlParams(CXD);
  var affid = GetUrlParams(AFFID);

  // pcode
  if (Pcode !== "" && Pcode !== undefined) {
    SetCookie("AFFILIATE_PCODE", Pcode, EXDAYS);
  } else {
    Pcode = GetCookie("AFFILIATE_PCODE");
  }

  // cxd
  if (cxd !== "" && cxd !== undefined) {
    SetCookie("AFFILIATE_CXD", cxd, EXDAYS);
  } else {
    cxd = GetCookie("AFFILIATE_CXD");
  }

  // affid
  if (affid !== "" && affid !== undefined) {
    SetCookie("AFFILIATE_AFFID", affid, EXDAYS);
  } else {
    affid = GetCookie("AFFILIATE_AFFID");
  }

  var utmInheritingDomains = domains;
  // eslint-disable-next-line no-useless-escape
  var utmRegExp = /(\&|\?)utm_[A-Za-z]+=[A-Za-z0-9]+/gi;
  var links = document.getElementsByClassName(targetElement);
  var utms = [
    "Pcode=" + Pcode,
    "affid=" + affid,
    "cxd=" + cxd,
    "utm_medium=" + utm_medium,
    "utm_source=" + utm_source,
    "utm_campaign=" + utm_campaign,
  ];

  for (var index2 = 0; index2 < links.length; index2 += 1) {
    for (var index = 0; index < links.length; index += 1) {
      var tempLink = links[index].href;
      var tempParts;
      if (tempLink) {
        if (tempLink.indexOf(utmInheritingDomains[index2]) > 0) {
          tempLink = tempLink.replace(utmRegExp, "");
          tempParts = tempLink.split("#");

          if (tempParts[0].indexOf("?") === -1) {
            tempParts[0] += "?" + utms.join("&");
          }
          tempLink = tempParts.join("#");
        }
      }

      links[index].href = tempLink;
    }
  }
};

export default AffiliateTracker;
