const Hero = ({
  heroStyle,
  title,
  desc,
  primaryBtnHref,
  primaryBtnText,
  secondaryBtnHref,
  secondaryBtnText,
}) => {
  return (
    <div className={`${heroStyle} bg-[#091f2c4d] `}>
      <div className="App-hero min-h-[781px]">
        <section className="container ">
          <div className="App-hero_content mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-16">
            <div className="items-center justify-center mt-[137px] ">
              <div className="lg:py-20 text-center ">
                <h1 className="max-w-[784px]"> {title}</h1>
                <p className="mt-4 emphasis_para max-w-[814px]">{desc}</p>

                <div className="mt-7 block sm:flex justify-center gap-5">
                  {primaryBtnText && (
                    <a
                      href={primaryBtnHref}
                      className="primary-btn inline-flex items-center port:!w-[100%]"
                    >
                      {primaryBtnText}
                    </a>
                  )}
                  {secondaryBtnText && (
                    <a
                      href={secondaryBtnHref}
                      className="secondary-btn inline-flex items-center port:!w-[100%] port:mt-4"
                    >
                      {secondaryBtnText}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Hero;
