import CardItems from "./lib/components/card";
import SiteFooter from "./lib/components/footer";
import Half from "./lib/components/half";
import Header from "./lib/components/header";
import Hero from "./lib/components/hero";
import Section from "./lib/components/section";
import Columns from "./lib/components/columns";
import "./lib/styles/App.scss";
import FullBlockCta from "./lib/components/full-block-cta";
import IconGroup from "./lib/components/icon-group";
import StepIcon from "./lib/icons/Property 1=Step-1-G.svg";
import StepIcon2 from "./lib/icons/Property 1=Step-2-G.svg";
import StepIcon3 from "./lib/icons/Property 1=Step-3-G.svg";
import Icon from "./lib/icons/60fps.svg";
import Icon2 from "./lib/icons/verified_user.svg";
import Icon3 from "./lib/icons/lock.svg";
import Icon4 from "./lib/icons/Vector.svg";
import Icon5 from "./lib/icons/headset_mic.svg";
import Icon6 from "./lib/icons/money_off.svg";
import Logo from "./lib/icons/go-logo.svg";
import Forex from "./lib/icons/Property 1=forex.svg";
import Candles from "./lib/icons/candlestick_chart.svg";
import AreaChart from "./lib/icons/area_chart.svg";
import Gold from "./lib/icons/Property 1=gold.svg";
import Oil from "./lib/icons/oil_barrel.svg";
import AutoGraph from "./lib/icons/auto_graph.svg";
import Mt4 from "./lib/icons/Property 1=mt4.svg";
import Mt5 from "./lib/icons/Property 1=mt5.svg";
import AddChart from "./lib/icons/add_chart.svg";
import ExpandedCta from "./lib/components/expanded-cta";
import Steps from "./lib/components/steps";
import { useAffiliateTracker } from "./lib/affiliate-tracker/useAffiliateTracker";

function App() {
  useAffiliateTracker(
    [
      "app.gomarkets.com",
      "gomarkets.eappform.com",
      "headless-cms-project-aa3d1.web.app",
    ],
    "primary-btn"
  );
  return (
    <div className="main">
      <div className="relative">
        {/* Header */}
        <Header
          btnText="Open Account"
          btnHref=""
          logo={<img alt="logo" src={Logo} className="w-[250px] h-[23.38px]" />}
          absolute={true}
          modal={false}
        />

        {/* Hero */}
        <Hero
          title="Trade Gold, Forex and 1000+ more instruments"
          desc={
            <>
              Forex, Shares, Indices, Gold, Oil & More - Reliable & Powerful
              MT4/MT5 Platforms - Leverage up to 500:1 - Free Education
              Resources - Tight Spreads from 0.0 pips with GO Plus+
            </>
          }
          primaryBtnText="Open Live Account"
          primaryBtnHref="https://headless-cms-project-aa3d1.web.app/au/"
          secondaryBtnText="Try Free Demo"
          secondaryBtnHref="#"
          heroStyle="bg-cover bg-no-repeat bg-[url(/public/Mutiple-angle-long2.png)]"
        />
      </div>

      {/* Icon Group */}
      <Section title="Why trade with GO Markets?">
        <div>
          <p className="text-center">
            Hundreds of opportunities to trade, tight spreads, transparent
            pricing, powerful platforms. Backed by unmatched support.
          </p>

          <IconGroup
            obj={[
              {
                icon: <img alt="icon" src={Icon} />,
                icon_title: "Low Spreads from 0.0 Pips",
              },
              {
                icon: <img alt="icon" src={Icon2} />,
                icon_title: "Regulated Broker",
              },
              {
                icon: <img alt="icon" src={Icon3} />,
                icon_title: "Segregated Client Funds",
              },
              {
                icon: <img alt="icon" src={Icon4} />,
                icon_title: "Fast Execution",
              },
              {
                icon: <img alt="icon" src={Icon5} />,
                icon_title: "24/5 Client Support",
              },
              {
                icon: <img alt="icon" src={Icon6} />,
                icon_title: "No Deposit Fees",
              },
            ]}
          />
        </div>
      </Section>

      {/* Steps */}
      <Section>
        <Steps
          title="Start trading in 3 easy steps"
          obj={[
            {
              icon: <img alt="icon" src={StepIcon} className="w-9 h-9" />,
              card_title: "Open an account",
              card_content: "Apply for a GO Markets Live Trading Account",
            },
            {
              icon: <img alt="icon" src={StepIcon2} className="w-9 h-9" />,
              card_title: "Fund Your Account",
              card_content: "Deposit the minimum funds required.",
            },
            {
              icon: <img alt="icon" src={StepIcon3} className="w-9 h-9" />,
              card_title: "Start Trading!",
              card_content: "Trade Gold, and 1000+ other instruments.",
            },
          ]}
          bg="dark"
          last_card_title="Join Today"
          last_card_content="Try a free demo"
          last_cardSecondaryBtnHref="Join Today"
          last_cardSecondaryBtnText="Open Live Account"
        />
      </Section>

      {/* Half */}
      <Section title="">
        <Half
          title="Trade with a Multi-Award Winning Broker"
          content={
            <>
              GO Markets has been awarded Best Forex Fintech Broker Global, Most
              Trusted Broker EU and Best Trading Support Asia! <br />
              <br />
              Global Forex Awards 2022
            </>
          }
          image={
            <img
              alt="hero"
              src={"image1.png"}
              className="inset-0 w-[90%] h-[90%]"
            />
          }
          imageStyle={"bg-[#E2E5EC] w-100 lg:h-[467px] rounded-[20px]"}
          orderLast={true}
          equalCols={true}
        />
      </Section>

      {/* Expanded Cta */}
      <Section>
        <ExpandedCta
          title="Get started with GO Markets"
          primaryBtnHref=""
          primaryBtnText="Open Live Account"
          secondaryBtnHref=""
          secondaryBtnText="Try Free Demo"
        />
      </Section>

      {/* 6 col */}
      <Section title="One account. Over thousands opportunities to trade.">
        <Columns col={3}>
          <CardItems
            obj={[
              {
                icon: <img alt="logo" src={Forex} className="w-12 h-12" />,
                card_title: "Forex",
                card_content:
                  "Trade wide range of currency pairs with a leading global CFD provider",
              },
              {
                icon: <img alt="logo" src={Candles} className="w-12 h-12" />,
                card_title: "Share CFD",
                card_content:
                  "Trade long or short on share CFDs on the Australian, US, Hong Kong, UK and German exchanges",
              },
              {
                icon: <img alt="logo" src={AreaChart} className="w-12 h-12" />,
                card_title: "Indices",
                card_content:
                  "Access a wide range of Index CFD trades via MT4 & MT5 Platforms",
              },
              {
                icon: <img alt="logo" src={Gold} className="w-12 h-12" />,
                card_title: "Metals",
                card_content:
                  "Access a range of precious metal pairs through GO Markets",
              },
              {
                icon: <img alt="logo" src={Oil} className="w-12 h-12" />,
                card_title: "Commodities",
                card_content:
                  "Trade one of the world’s most heavily-traded markets",
              },
              {
                icon: <img alt="logo" src={AutoGraph} className="w-12 h-12" />,
                card_title: "Treasuries",
                card_content:
                  "Trade the most liquid bonds futures contract across the US, Europe, UK and Japan",
              },
            ]}
            bg="light"
          />
        </Columns>
      </Section>

      {/* Combo */}
      <Section title="">
        <div>
          <Half
            title="Platform Power"
            content={
              <>
                We're one of Australia's first MetaTrader brokers. Our expertise
                helps you intuitively execute trading strategies on
                market-leading platforms.
              </>
            }
            image={
              <img
                alt="hero"
                src={"Powerful-metaTrader-Platforms1.png"}
                className="inset-0"
              />
            }
            imageStyle={""}
            primaryBtnHref="/"
            primaryBtnText="Open Live Account"
            equalCols={false}
          />
          <div className="mt-10 flex justify-center">
            {/* 3 col */}
            <Columns col={3}>
              <CardItems
                obj={[
                  {
                    icon: <img alt="logo" src={Mt4} className="w-12 h-12" />,
                    card_title: "Metatrader 4",
                    card_content:
                      "The world's most popular trading platform for your phone, tablet or desktop",
                  },
                  {
                    icon: <img alt="logo" src={Mt5} className="w-12 h-12" />,
                    card_title: "Metatrader 5",
                    card_content:
                      "A more powerful release of MetaTrader with greater functionality.",
                  },
                  {
                    icon: (
                      <img alt="logo" src={AddChart} className="w-12 h-12" />
                    ),
                    card_title: "Trading Tools",
                    card_content:
                      "We have a range of trading tools to help you manage your trading risk, improve your trading strategies and stay in the know.",
                  },
                ]}
                bg="light"
              />
            </Columns>
          </div>
        </div>
      </Section>

      {/* Full width cta */}
      <Section title="Trade Better with GO Markets">
        <FullBlockCta
          primaryBtnHref="/"
          primaryBtnText="Open Live Account"
          desc=" Forex, Shares, Indices, Gold, Oil & More - Reliable & Powerful MT4/MT5 Platforms - Leverage up to 500:1 - Free Education Resources - Tight Spreads from 0.0 pips with GO Plus+"
        />
      </Section>

      {/* Footer */}
      <SiteFooter
        logo={
          <img alt="logo" src={Logo} className="w-[194.11px] h-[23.38px]" />
        }
        ftrInfo={[
          {
            title: "CONTACT",
            subContent: [
              {
                content: "Australia",
                info: "1800 88 55 71",
                link: "tel:1800 88 55 71",
              },
              {
                content: "UK",
                info: "+44 203 637 7618",
                link: "tel:+44 203 637 7618",
              },
              {
                content: "CHINA",
                info: "400 120 8537",
                link: "tel:400 120 8537",
              },
              {
                content: "INTERNATIONAL",
                info: "+61 3 8566 7680",
                link: "tel:+61 3 8566 7680",
              },
            ],
          },
          {
            title: "SUPPORT",
            subContent: [
              {
                content: "Email",
                info: " support@gomarkets.com",
                link: "mailto:support@gomarkets.com",
              },
              {
                content: " New Accounts",
                info: "newaccounts@gomarkets.com",
                link: "mailto:newaccounts@gomarkets.com",
              },
            ],
          },
        ]}
        ftrInfo2={[
          {
            title: "LEGAL DOCS",
            content: [
              { title: "Privacy Policy", link: "/" },
              { title: "Terms and Conditions", link: "/" },
              { title: "Product Disclosure Statement", link: "/" },
              { title: "Financial Services Guide", link: "/" },
            ],
          },
        ]}
        textBottom={
          <>
            <span className="font-medium">
              Our products carry a high level of risk, including the risk of
              losing substantially more than your initial expenditure. Also, you
              do not own or have any rights to the underlying assets.
            </span>
            <br /> <br />
            GO Markets Pty Ltd does not guarantee the performance or return of
            capital. You should only trade in risk capital (capital you can
            afford to lose). Historical financial performance of any underlying
            instrument/market is no guarantee or indicator of future
            performance.
            <br /> <br /> Our services may not be suitable for all investors,
            ensure that you fully understand the risks involved, and seek
            independent advice if necessary. A Financial Services Guide (FSG)
            and Product Disclosure Statement (PDS) for our products are
            available from GO Markets Pty Ltd to download at this website, and
            hard copies can be obtained by contacting our offices. <br /> <br />{" "}
            Please note, your call may be recorded for training and monitoring
            purposes. Any advice provided to you on this website or by our
            representatives is general advice only, and does not take into
            account your objectives, financial situation or needs. Consider the
            appropriateness of our advice and our PDS documents before making
            any decision about using our products or services. <br /> <br />
            Information on this site is not directed at residents in any country
            or jurisdiction where such distribution or use would be contrary to
            local law or regulation. The GO Markets AFSL authorises us to
            provide financial services to people in Australia. <br /> <br />{" "}
            However, GO Markets complies with the obligations arising from our
            AFSL in respect of the financial services provided to all of our
            clients, including those who reside outside of Australia. This
            website is owned and operated by GO Markets. All contents © 2020 GO
            Markets Pty Ltd. All rights reserved. AFSL 254963. ABN 85 081 864
            039. ACN 081 864 039.
          </>
        }
      />
    </div>
  );
}

export default App;
