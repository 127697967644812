const Columns = ({ children, col }) => {
  return (
    <div
      className={`grid gap-y-4 lg:gap-y-12  gap-x-4  grid-cols-1 sm:grid-cols-2 ${
        col === 3
          ? "lg:grid-cols-3 lg:gap-x-12"
          : col === 4
          ? "lg:grid-cols-4 gap-x-4"
          : col === 6
          ? "lg:grid-cols-6 grid-cols-2 gap-x-4"
          : "lg:grid-cols-3 lg:gap-x-12"
      }`}
    >
      {children}
    </div>
  );
};
export default Columns;
