import { Card } from "flowbite-react";

const CardItems = ({ bg, obj }) => {
  return (
    <>
      {obj.map((item, index) => (
        <div key={index} className="max-w-sm cardItem">
          <Card
            className={
              bg === "dark"
                ? "!bg-primaryDark text-white"
                : bg === "light"
                ? "!bg-darkSix"
                : "!bg-darkSix"
            }
          >
            {item.icon}
            <h4>{item.card_title}</h4>
            <p>{item.card_content}</p>
          </Card>
        </div>
      ))}
    </>
  );
};

export default CardItems;
