import { GetCookie, SetCookie } from "./cookies";

// write initial referrer
export const WriteInitRef = () => {
  var value;
  var utmMedium = "";
  var exdays = 30;
  value = document.referrer;
  if (IsInvalid(value)) {
    value = "direct";
  }
  SetCookie("referrer", value, exdays);
  SetCookie("utm_source", value, exdays);
  SetCookie("utm_medium", utmMedium, exdays);
};

// write last referrer
export const WriteLastRef = () => {
  var value;
  var exdays = 30;
  value = document.referrer;
  if (!IsSameDomain(value)) {
    if (IsInvalid(value)) {
      value = "direct";
    }
    SetCookie("last_referrer", value, exdays);
  }
};

// check same domain referrer
export const IsSameDomain = (referrer) => {
  var hostname;
  var domain;
  domain = "";
  hostname = document.location.hostname;
  return referrer.indexOf(domain) > -1 || referrer.indexOf(hostname) > -1;
};

// check invalid referrer
export const IsInvalid = (referrer) => {
  return referrer === "" || referrer === void 0;
};

// get initial referrer
export const GetInitialReferrer = () => {
  return GetCookie("referrer");
};

// check last referrer
export const GetLastReferrer = () => {
  return GetCookie("last_referrer");
};
