const Section = ({ customStyle, title, children }) => {
  return (
    <section className={`container ${customStyle ? customStyle : ""}`}>
      <div className="section_content mx-auto max-w-screen-xl py-4 px-4 sm:px-6 lg:py-16 lg:px-16 lg:my-10">
        {title ? (
          <h2 className="max-w-[934px] text-center sm:mb-14">{title}</h2>
        ) : (
          ""
        )}
        <div className="max-w-full mx-auto py-12 px-4 sm:px-6 lg:py-2 lg:px-8 sm:flex sm:items-center sm:justify-center">
          {children}
        </div>
      </div>
    </section>
  );
};
export default Section;
