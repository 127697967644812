import { Card } from "flowbite-react";

const Steps = ({
  last_card_title,
  last_card_content,
  last_cardSecondaryBtnHref,
  last_cardSecondaryBtnText,
  title,
  obj,
  bg,
}) => {
  return (
    <div className="stacked ">
      <h2 className="max-w-[934px] mb-10 sm:mb-14 block m-auto text-center">
        {title}
      </h2>

      <div className="grid sm:gap-y-2 grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-4">
        {obj.map((item, index) => (
          <div key={index} className="cardItem flex justify-center">
            <Card
              className={`w-[283px] !h-[220px] port:text-center ${
                bg === "dark"
                  ? "!bg-primaryDark text-white "
                  : bg === "light"
                  ? "!bg-darkSix"
                  : "!bg-darkSix"
              }`}
            >
              <div className="block port:m-auto">{item.icon}</div>
              <h4>{item.card_title}</h4>
              <p>{item.card_content}</p>
            </Card>
          </div>
        ))}
        <div className="lastStep flex justify-center">
          <Card>
            <h3 className="text-[30px] leading-9">{last_card_title}</h3>
            <a
              href={last_cardSecondaryBtnHref}
              className="secondary-btn inline-flex items-center xl:!w-[180px]"
            >
              {last_cardSecondaryBtnText}
            </a>
            <p>{last_card_content}</p>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Steps;
