import { Footer } from "flowbite-react";
import LogoDefault from "../icons/go-logo.svg";

const SiteFooter = ({ logo, ftrInfo, ftrInfo2, textBottom }) => {
  return (
    <Footer>
      <div className="w-full mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-16">
        <div className="w-full py-6 px-4 sm:flex sm:items-center sm:justify-between text-primaryLight footer-content">
          <Footer bgDark={true}>
            <div className="w-full container">
              <div className="w-full py-6 px-2 sm:flex sm:items-center sm:justify-between">
                <div className="flex justify-start">
                  {logo !== undefined && logo !== "" ? (
                    logo
                  ) : (
                    <img
                      alt="logo"
                      src={LogoDefault}
                      className="w-[194.11px] h-[23.38px]"
                    />
                  )}
                </div>
              </div>
              <div
                className={ftrInfo.length > 0 ? "w-full py-8 px-4 sm:px-6" : ""}
              >
                {ftrInfo.length > 0 && (
                  <div className="grid grid-cols-1 gap-12 md:grid-cols-3 mb-10">
                    {ftrInfo.map((info, index) => {
                      return (
                        <div key={index}>
                          <h2 className="mb-6 text-sm font-semibold uppercase ">
                            {info.title}
                          </h2>
                          <ul className="text-white dark:text-gray-400 font-bold">
                            <div className="grid grid-rows-2 grid-flow-col gap-2">
                              {info.subContent
                                ? info.subContent.map((sub, i) => {
                                    return (
                                      <div key={i}>
                                        {sub.content}
                                        <li className="mb-4">
                                          <a
                                            href={sub.link}
                                            className="hover:underline font-light"
                                          >
                                            {sub.info}
                                          </a>
                                        </li>
                                      </div>
                                    );
                                  })
                                : ""}
                            </div>
                          </ul>
                        </div>
                      );
                    })}
                    {ftrInfo2.map((info, index) => {
                      return (
                        <div key={index}>
                          <h2 className="mb-6 text-sm font-semibold uppercase">
                            {info.title}
                          </h2>
                          <ul className="text-white dark:text-gray-400 font-light">
                            {info.content.map((i, index) => {
                              return (
                                <li key={index}>
                                  <a
                                    href={i.link}
                                    className="hover:underline block"
                                  >
                                    {i.title}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                )}

                {textBottom.props.children != null &&
                  textBottom.props.children !== "" && (
                    <>
                      <hr className="divider" />
                      <div className="font-light">{textBottom}</div>
                    </>
                  )}
              </div>
            </div>
          </Footer>
        </div>
      </div>
    </Footer>
  );
};

export default SiteFooter;
