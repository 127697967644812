import { useEffect } from "react";
import AffiliateTracker from "./affiliate-tracker";

// adding script tags
export function useAffiliateTracker(domains, targetElement) {
  useEffect(() => {
    const onPageLoad = () => {
      AffiliateTracker(domains, targetElement);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, [domains, targetElement]);
}
