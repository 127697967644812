const ExpandedCta = ({
  title,
  primaryBtnHref,
  primaryBtnText,
  secondaryBtnHref,
  secondaryBtnText,
}) => {
  return (
    <div className="grid lg:grid-cols-2 gap-2 expandedCta sm:w-[1144px] sm:h-[140x]">
      <div>
        <h4 className="text-primaryLight text-[30px] leading-9 text-center">
          {title}
        </h4>
      </div>
      <div>
        <div className="sm:flex justify-center gap-5">
          <a
            href={primaryBtnHref}
            className="primary-btn items-center mt-4 sm:mt-0"
          >
            {primaryBtnText}
          </a>
          <a
            href={secondaryBtnHref}
            className="secondary-btn items-center mt-4 sm:mt-0"
          >
            {secondaryBtnText}
          </a>
        </div>
      </div>
    </div>
  );
};
export default ExpandedCta;
