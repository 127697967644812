//organic types
var currentReferer = document.referrer;

export const GOOGLE = currentReferer.includes("google");
export const BING = currentReferer.includes("bing");
export const BAIDU = currentReferer.includes("baidu");
export const DUCKDUCKGO = currentReferer.includes("duckduckgo");
export const YAHOO = currentReferer.includes("yahoo");
export const ESCOSIA = currentReferer.includes("ecosia");
export const YANDEX = currentReferer.includes("yandex");
export const QWANT = currentReferer.includes("qwant");

//cookie expiry
export const EXDAYS = 30;

//url params
export const PCODE = "Pcode";
export const CXD = "cxd";
export const AFFID = "affid";
