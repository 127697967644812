const Half = ({
  title,
  content,
  image,
  orderLast,
  primaryBtnHref,
  primaryBtnText,
  imageStyle,
  equalCols,
}) => {
  return (
    <div
      className={`grid grid-cols-1 sm:gap-8 lg:gap-16 
      ${equalCols ? "sm:grid-cols-2" : "sm:grid-cols-3 "}
     `}
    >
      <div className={`lg:py-5 ${orderLast ? "order-last" : ""}`}>
        <div className={`${!orderLast ? "text-center sm:text-start" : ""} `}>
          <h2
            className={`inline-flex items-baseline max-w-[546px] gap-6 ${
              !orderLast ? "text-[34px] leading-[40.8px]" : ""
            }`}
          >
            {title}
          </h2>
        </div>

        <p className={`my-5 ${!orderLast ? "text-center sm:text-start" : ""}`}>
          {content}
        </p>
        {primaryBtnText !== undefined ? (
          <a
            href={primaryBtnHref}
            className="primary-btn inline-flex items-center"
          >
            {primaryBtnText}
          </a>
        ) : null}
      </div>
      <div
        className={`flex justify-center items-center  ${imageStyle} ${
          equalCols ? "" : "col-span-2"
        }  `}
      >
        {image}
      </div>
    </div>
  );
};
export default Half;
