import Columns from "./columns";

const IconGroup = ({ obj }) => {
  return (
    <div className="flex justify-center">
      <Columns col={6}>
        {obj.map((itemm, index) => {
          return (
            <div key={index} className="iconGroupItem">
              <div className="icon">{itemm.icon}</div>
              <p>{itemm.icon_title}</p>
            </div>
          );
        })}
      </Columns>
    </div>
  );
};

export default IconGroup;
