const FullBlockCta = ({ desc, primaryBtnHref, primaryBtnText }) => {
  return (
    <div className="block">
      <p className="text-center max-w-[934px] emphasis_para">{desc}</p>

      <div className="flex justify-center mt-8">
        <a href={primaryBtnHref} className="primary-btn items-center">
          {primaryBtnText}
        </a>
      </div>
    </div>
  );
};
export default FullBlockCta;
